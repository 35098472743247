import {IconButton, Tooltip} from "@material-ui/core";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import GlobalGroupActions from "../../authors/list/GlobalGroupActions";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import {OpenArticleDialogPayload, setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {MoveIcon} from "../../commons/icon/icons";
import BapArticle from "../actions/BapArticle";
import PdfArticle from "../actions/PdfArticle";
import RemoveMultipleArticle from "../actions/RemoveMultipleArticle";
import DownloadMultipleArticleXml from "../actions/DownloadMultipleArticleXml";
import {ArticleStatus, fixedAttachments} from "../../../types/article";
import {createHtml} from "../../../utils/common-utils";
import PushOnlineMultipleArticles from "../actions/PushOnlineMultipleArticles";

type Props = {
    grid?: boolean
}

export default function ArticlesListActions({grid}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const [totalCount, selectedIds, allSelected, allPageSelected, searchResults]: [number, string[], boolean, boolean, ArticleSearchResult[]] =
        useSelector((state: State) => {
            const store = state.search.common[SearchContext.ARTICLE]
            return [store.totalCount, store.selectedIds, store.allSelected, store.allPageSelected, store.searchResults as ArticleSearchResult[]]
        });

    const selectedArticles = useMemo(() => searchResults
        .filter(article => selectedIds.indexOf(article.id) !== -1), [selectedIds])

    const isMovingDisabled = useMemo(() => {
        //check different periodical ids
        if (Object.values(selectedArticles.map(article => article.periodicalId)
            .reduce((a, c) => (a[c] = (a[c] || 0) + 1, a), Object.create(null))).length > 1) {
            return true

        }

        if (selectedArticles.some(article => article.status === ArticleStatus.PUBLISHED || article.status === ArticleStatus.PUBLISHING)) {
            return true
        }

        return selectedArticles
            .some(article => fixedAttachments.includes(article.attached));


    }, [selectedIds])

    const openArticleDialog = () => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.MOVE_MULTIPLE,
            default: {
                periodicalId: selectedArticles[0].periodicalId
            }
        } as OpenArticleDialogPayload))
    }

    const displayBap = () => {
        if (!allSelected) {
            return <BapArticle size="small" ids={selectedIds} context={SearchContext.ARTICLE} color="inherit"/>
        } else {
            return <></>
        }
    }

    const displayDownload = () => {
        if (!allSelected) {
            return <DownloadMultipleArticleXml ids={selectedIds.map(id => {
                    return {
                        id: id,
                        type: searchResults.find(x => x.id === id)?.isBundle ? 'bundle' : 'article'
                    }
                }
            )
            }/>
        } else {
            return <></>
        }
    }

    const displayPdf = () => {
        if (!allSelected) {
            return <PdfArticle ids={selectedIds} context={SearchContext.ARTICLE} color="inherit"/>
        } else {
            return <></>
        }
    }

    return (
        <GlobalGroupActions grid={grid} allSelected={allSelected} totalCount={totalCount}
                            allPageSelected={allPageSelected}
                            ctx={SearchContext.ARTICLE}
                            selectedCount={selectedIds.length} type={"articles"} enableSelectedAll={false}>

            {displayPdf()}

            {displayBap()}

            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}>
                <Tooltip title={isMovingDisabled ?
                    <span dangerouslySetInnerHTML={createHtml(t('articles.disable_multiple_move'))}>
                    </span> : t('articles.move', {count: selectedIds.length}) ?? ''
                }>
                    <span>
                            <IconButton
                                color="inherit"
                                disabled={isMovingDisabled}
                                onClick={() => openArticleDialog()}>
                                <MoveIcon/>
                            </IconButton>
                        </span>
                </Tooltip>
            </Auth>

            <RemoveMultipleArticle selectedIds={selectedIds} allSelected={allSelected} searchResults={searchResults}/>

            <PushOnlineMultipleArticles/>
            {displayDownload()}
        </GlobalGroupActions>
    )
}
