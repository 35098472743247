import React, {useEffect, useMemo, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";

import {initFontoSession} from "../../redux/actions/fonto.actions";
import {State} from "../../redux/reducers";
import {Link as RouterLink, useHistory, useParams} from "react-router-dom";
import {fetchArticle, uploadArticle} from "../../redux/actions/article/article.actions";
import {fetchBundle} from "../../redux/actions/bundle.actions";
import {currentFetchedArticleSelector} from "../../redux/selectors/article/article.selectors";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../utils/roles";
import {currentFetchedBundleSelector} from "../../redux/selectors/bundle.selectors";
import Auth, {isAuth} from "../Auth";
import {Grid, Link, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {useTranslation} from "react-i18next";
import {ImportIcon, ReplyIcon, ViewIcon} from "../../icon";
import {DownloadIcon, GeneratePdfIcon} from "../commons/icon/icons";
import {uuidv4} from "../../utils/uuid";
import {dispatchArticleAction} from "../../redux/actions/workflow.actions";
import {ArticleActionName} from "../../types/workflow";
import {useInterval} from "../commons/hooks/use-interval";
import axios, {AxiosResponse} from "axios";
import {downloadArticle} from "../../utils/common-utils";
import {notify, notifyError} from "../../redux/actions/notification.actions";
import PostAddIcon from "@material-ui/icons/PostAdd";
import {setArticleDialogMode} from "../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../redux/reducers/article/article-dialog.reducer";
import ImportArticle from "../import-article/ImportArticle";

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'calc(100vh - 68px)',
        width: "100%"
    },
    iframe: {
        border: "none",
        width: 'calc(100% - 45px)'
    },
    rightPanel: {
        position: "absolute",
        right: 0,
        top: 68,
        height: 'calc(100% - 71px)',
        borderTop: '3px dotted ' + theme.palette.secondary.main,
        width: 45,
        background: "rgba(0, 0, 0, 0.87)",
        color: "white"
    },
    bottomRight: {
        position: "absolute",
        bottom: 30,
        width: '100%'
    }
}));

type Props = {
    isBundle: boolean
}

export default ({isBundle}: Props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const roles = useSelector(({auth}: State) => auth?.roles ?? [])
    const {id} = useParams() as { id: string }
    const {t} = useTranslation()
    const history = useHistory()

    useEffect(() => {
        dispatch(initFontoSession());
        if (isBundle) {
            dispatch(fetchBundle(id))
        } else {
            dispatch(fetchArticle(id))
        }
    }, [dispatch, id])

    const handleUnload = (ev) => {
        ev.preventDefault();
        return ev.returnValue = 'Êtes vous sûr de vouloir quitter cette page ? Des modifications en cours pourraient être perdues';
    }

    useEffect(() => {
        window.addEventListener("beforeunload", handleUnload);
        return () => {
            window.removeEventListener("beforeunload", handleUnload)
        }
    }, [])

    useInterval(() => {
        if (isBundle) {
            dispatch(fetchBundle(id))
        } else {
            dispatch(fetchArticle(id))
        }
    }, 10 * 1000)

    const bundle = useSelector(currentFetchedBundleSelector)
    const article = useSelector(currentFetchedArticleSelector)

    const isValid = useMemo(() => {
        if (isBundle) {
            return (bundle?.isValid ?? false) && (bundle?.lastRequestPlacardProcessStatus === null)
        } else {
            return (article?.isValid ?? false) && (article?.lastRequestPlacardProcessStatus === null)
        }
    }, [bundle, article])

    const initSession = useSelector(({fonto}: State) => fonto.session)

    const generatePDF = (articleId: string) => {
        const uuid = uuidv4();

        dispatch(dispatchArticleAction({
            id: articleId,
            action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
            uuid
        }))
    }

    const openCreateArticleDialog = () => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CREATE
        }))
    }

    let config: {};
    if (isBundle) {
        config = {
            cmsBaseUrl: "/api/fonto/",
            editSessionToken: initSession.id,
            documentIds: [id],
            heartbeat: 15,
            expertMode: bundle ? isAuth(roles, [ADMIN, PRODUCER], bundle.periodical) : false,
            nature: "bundle",
            isBundle: true,
            user: {
                id: initSession.id,
                displayName: initSession.userName
            }
        }
    } else {
        config = {
            cmsBaseUrl: "/api/fonto/",
            editSessionToken: initSession.id,
            documentIds: [id],
            heartbeat: 15,
            expertMode: article ? isAuth(roles, [ADMIN, PRODUCER], article.periodicalId) : false,
            nature: article?.nature,
            type: article?.type,
            isBundle: false,
            user: {
                id: initSession.id,
                displayName: initSession.userName
            }
        }
    }

    const token = useSelector((state: State) => state.auth.authenticated ? state.auth.token : '')

    const inputFile = useRef(null);

    const handleFileUpload = e => {
        const {files} = e.target;
        if (files && files.length) {
            const notification = notify('Import en cours', {variant: 'info', persist: true}, false)
            dispatch(notification)
            dispatch(uploadArticle({files, notificationKey: notification.payload.key}))
        }
    }
    const downloadXml = () => {
        const headers = {
            'Authorization': `Bearer ${token}`
        };

        axios.get(`/api/${isBundle ? "bundle" : "article"}/xml/${id}`, {headers})
            .then((r: AxiosResponse) => downloadArticle(`${id}.xml`, r.data as string))
            .catch((err: any) => {
                dispatch(notifyError(+err.response.status, {type: isBundle ? 'BUNDLE' : 'ARTICLE'}))
            })
    }
    const url = `/fonto/?scope=${JSON.stringify(config)}`;

    useEffect(() => {
        if (initSession && initSession.id) {
            document.getElementById('fonto-iframe')?.focus();
        }
    }, [initSession, initSession.id])

    return (
        <div className={classes.root}>
            {(initSession && initSession.id) &&
            <iframe id="fonto-iframe" className={classes.iframe} title={id} src={url} width="100%" height="100%"/>}
            <div className={classes.rightPanel}>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                >
                    <Grid item>
                        <Tooltip title={t('misc.back') ?? ''}>
                            <IconButton onClick={() => history.goBack()} color={"inherit"}>
                                <ReplyIcon/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title={t('misc.preview') ?? ''}>
                            <Link
                                to={{pathname: `/${isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${id}`}}
                                target="_blank" component={RouterLink} color={"inherit"}>
                                <IconButton color={"inherit"}>
                                    <ViewIcon color={"inherit"}/>
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Grid>
                    {
                        isValid && <Grid item>
                            <Tooltip title={t('workflows.generate_placard') ?? ''}>
                                <IconButton color={"inherit"}
                                            onClick={() => {
                                                generatePDF(id)
                                            }}>
                                    <GeneratePdfIcon color={"inherit"}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                    <Grid item>
                        <Tooltip title={t('misc.download') ?? ''}>
                            <IconButton onClick={() => downloadXml()} color={"inherit"}>
                                <DownloadIcon color={"inherit"}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>

                <div className={classes.bottomRight}>
                    <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER]}>
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            alignItems="center"
                        >
                            <Grid item>
                                <Tooltip title={t('articles.add') ?? ''}>
                                    <IconButton onClick={openCreateArticleDialog} color={"inherit"}>
                                        <PostAddIcon color={"inherit"}/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Auth>

                    <Auth requiredRoles={[ADMIN, PRODUCER]}>
                        <Grid item>
                            <ImportArticle/>

                            <input
                                style={{display: 'none'}}
                                accept={'.xml'}
                                ref={inputFile}
                                onChange={handleFileUpload}
                                type="file"
                            />
                        </Grid>
                    </Auth>
                </div>
            </div>
        </div>
    )
}
