import {Button, IconButton} from "@material-ui/core";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ArticleSearchResult, ElementContext, Filters, SearchContext} from "../../../types/common-search";
import Tooltip from "@material-ui/core/Tooltip";
import {GeneratePdfIcon} from "../../commons/icon/icons";
import {ArticleActionName} from "../../../types/workflow";
import {dispatchArticleAction, dispatchMultipleArticlesAction} from "../../../redux/actions/workflow.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {uuidv4} from "../../../utils/uuid";
import {processInProgress} from "../../../redux/selectors/workflow.selectors";
import {ADMIN, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {ArticleResponse} from "../../../types/article";
import Auth from "../../Auth";


type Props = {
    article?: ArticleSearchResult | ArticleResponse
    ids?: string[]
    context?: SearchContext | ElementContext
    forceOptions?: Filters
    size?: 'small' | 'medium'
    buttonClassName?: any
    isButton?: boolean
    color?: 'primary' | 'secondary' | 'inherit'
}

function PdfArticle({article, ids, context, forceOptions, size, buttonClassName, isButton, color}: Props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const processInProgressSelector = useSelector(processInProgress)
    const [currentProcess, setCurrentProcess] = useState<string>();

    const displayButton = () => {
        if (isButton) {
            return <Button
                className={'lex-left-button'}
                fullWidth
                startIcon={<GeneratePdfIcon/>}
                size={"small"}
                onClick={() => generatePDF(article!.id!!)}>
                {t('workflows.generate_placard')}
            </Button>
        } else {
            return <IconButton
                size={size}
                color={color ?? "default"}
                className={buttonClassName}
                onClick={() => {
                    if (article) {
                        generatePDF(article.id!!)
                    } else {
                        generatePDFs()
                    }
                }}>
                <GeneratePdfIcon/>
            </IconButton>
        }

    }

    const generatePDFs = () => {
        const uuid = uuidv4();
        dispatch(dispatchMultipleArticlesAction({
            ids: ids!!,
            action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
            uuid
        }))
        setCurrentProcess(uuid)
    }

    const generatePDF = (articleId: string) => {
        const uuid = uuidv4();

        dispatch(dispatchArticleAction({
            id: articleId,
            action: ArticleActionName.GENERATE_ARTICLE_PLACARD,
            uuid,
            context,
            forceOptions
        }))
        setCurrentProcess(uuid)
    }

    return (
        <Auth requiredRoles={[ADMIN, PRODUCER, MANAGER, EDITOR]}
              periodicalRestriction={article ? article.periodicalId : undefined}>
            <Tooltip
                title={t('workflows.generate_placard') ?? ''}>
                {currentProcess && processInProgressSelector.includes(currentProcess) ?
                    <CircularProgress
                        color="inherit" size={size && size == 'small' ? 10 : 15}
                        style={size === undefined || size === 'medium' ? {marginTop: 15} : {}}/>
                    : displayButton()}
            </Tooltip>
        </Auth>
    )
}

export default PdfArticle;
