import React, {useContext} from "react";
import {Button, Grid, Size} from "@material-ui/core";
import {CopyIcon, DeleteIcon, MoveIcon} from "../icon/icons";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import MoreActionsButton from "../MoreActionsButton";
import {useTranslation} from "react-i18next";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import {OpenArticleDialogPayload, setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {useDispatch, useSelector} from "react-redux";
import {DeleteArticleDialogContext} from "../context/DeleteArticleDialogContext";
import DownloadXmlArticle from "../../articles/actions/DownloadArticleXml";
import {ArticleStatus, fixedAttachments} from "../../../types/article";
import {State} from "../../../redux/reducers";
import {ArticleSearchResult, SearchContext} from "../../../types/common-search";
import EditArticle from "../../articles/actions/EditArticle";
import OrganizeBundle from "../../articles/actions/OrganizeBundle";
import PostAddIcon from "@material-ui/icons/PostAdd";
import PdfArticle from "../../articles/actions/PdfArticle";
import {GridSize} from "@material-ui/core/Grid";
import PushOnlineArticle from "../../articles/actions/PushOnlineArticle";

type Props = {
    article: ArticleSearchResult
    forceOptions: any
    context: any
    size: Size
    xs?: GridSize
}

const ArticlesMoreActionsButtonOnOrganizeCDF = React.memo(({xs, size, article, forceOptions, context}: Props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const roles = useSelector(({auth}: State) => auth?.roles ?? []);

    const {openConfirmationDialog, setArticleBeingDeleted} = useContext(DeleteArticleDialogContext)

    const openArticleDialog = (article, mode: ArticleDialogMode) => {
        dispatch(fetchPublicationsOfPeriodical(article.periodicalId))
        dispatch(setArticleDialogMode({
            mode: mode,
            default: article,
            source: mode === ArticleDialogMode.MOVE ? article.id : undefined
        } as OpenArticleDialogPayload))
    }

    const handleAddArticleInBundle = (article: ArticleSearchResult) => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CREATE,
            default: {
                bundleId: article.id,
                periodicalId: article.periodicalId,
                publicationId: article.publicationId,
                planNodeId: article.planNodeId,
                type: undefined,
                isBundle: false
            }
        }))
    }

    const displayDuplicateButton = () => {
        if (!article.isBundle) {
            return (<Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                          periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        fullWidth
                        startIcon={<CopyIcon/>}
                        size={"small"}
                        onClick={() => {
                            openArticleDialog(article, ArticleDialogMode.COPY)
                        }}>
                        {t('articles.copy')}
                    </Button>
                </Grid>
            </Auth>)
        }
    }

    return <Grid item xs={xs}>
        <MoreActionsButton size={size}>
            {/* poussser en ligne */}

            {displayDuplicateButton()}

            {(!fixedAttachments.includes(article.attached) && [ArticleStatus.IN_PROGRESS, ArticleStatus.READY].includes(article.status)) &&
            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                  periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        size={"small"}
                        fullWidth
                        startIcon={<MoveIcon/>}
                        onClick={() => {
                            openArticleDialog(article, ArticleDialogMode.MOVE)
                        }}>
                        {t(article.isBundle ? 'bundles.move' : 'articles.move')}
                    </Button>
                </Grid>
            </Auth>}

            {(!fixedAttachments.includes(article.attached) && article.publicationOnlineDate === null) &&
            <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, EDITOR, PRODUCER, MANAGER]}
                  periodicalRestriction={article.periodicalId}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        size={"small"}
                        fullWidth
                        startIcon={<DeleteIcon/>} onClick={() => {
                        setArticleBeingDeleted(article)
                        openConfirmationDialog(true)
                    }}>
                        {t(article.isBundle ? 'bundles.delete' : 'articles.delete')}
                    </Button>
                </Grid>
            </Auth>}

            {/* télécharger xml */}
            <DownloadXmlArticle article={article}/>

            <>
                {article &&
                <Grid item><EditArticle article={article} isButton={true} size={"small"}/></Grid>}

                {article && article.isBundle &&
                <Grid item><OrganizeBundle document={article} isButton={true} size={"small"}/></Grid>}
            </>

            {(article.isBundle && article.lastRequestPlacardProcessStatus === null && (article.status === ArticleStatus.IN_PROGRESS
                || article.status === ArticleStatus.READY)) &&
            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                <Grid item>
                    <Button
                        className={'lex-left-button'}
                        size={"small"}
                        fullWidth
                        startIcon={<PostAddIcon/>}
                        onClick={() => handleAddArticleInBundle(article)}>
                        {t('bundles.add_article') ?? ''}
                    </Button>
                </Grid>
            </Auth>}


            {article && (!article || (article && (article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY)
                && article.lastRequestPlacardProcessStatus === null) && article.isValid) &&
            <Grid item>
                <PdfArticle size={"small"}
                            article={article} isButton={true}
                            buttonClassName={'lex-left-button'}
                            context={context}
                            forceOptions={forceOptions}
                />
            </Grid>
            }

        </MoreActionsButton>
    </Grid>
})

export default ArticlesMoreActionsButtonOnOrganizeCDF
