import React from "react";
import {Grid} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {EditIcon} from "../../../icon";
import {useDispatch} from "react-redux";
import ImageIcon from '@material-ui/icons/Image';
import DescriptionIcon from '@material-ui/icons/Description';
import {useTranslation} from "react-i18next";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import Auth from "../../Auth";
import {ADMIN, ADMIN_ADS, ADMIN_ARTICLES, EDITOR, MANAGER, PRODUCER} from "../../../utils/roles";
import Tooltip from "@material-ui/core/Tooltip";
import {AdSearchResult, ArticleSearchResult, SearchContext} from "../../../types/common-search";
import PostAddIcon from "@material-ui/icons/PostAdd";
import {ArticleStatus, ArticleType} from "../../../types/article";
import {toggleArticleCover} from "../../../redux/actions/article/article.actions";
import {setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import {
    AddToPlanIcon,
    BundleIcon,
    DetachedPlanIcon,
    SetFrontCoverIcon,
    UnsetFrontCoverIcon
} from "../../commons/icon/icons";
import ArticlesMoreActionsButton from "../../commons/button/ArticlesMoreActionsButton";
import PdfArticle from "../../articles/actions/PdfArticle";
import BapArticle from "../../articles/actions/BapArticle";
import RevertBapArticle from "../../articles/actions/RevertBapArticle";
import ArticleStatusList from "../../articles/list/ArticleStatusList";
import {Publication} from "../../../types/publication";
import {BundleResponse} from "../../../types/bundle";
import {forceBundleOptions, forcePublicationOptions} from "../../../utils/publication-utils";
import OrganizeBundle from "../../articles/actions/OrganizeBundle";
import EditArticle from "../../articles/actions/EditArticle";
import UnlockPdfArticle from "../../articles/actions/UnlockPdfArticle";
import {makeStyles} from "@material-ui/core/styles";
import DateTime from "../../commons/DateTime";
import {setGlobalTreeDropData} from "../../commons/tree/TreeViewer";
import ArticlesMoreActionsButtonOnOrganizeCDF from "../../commons/button/ArticlesMoreActionsButtonOnOrganizeCDF";
import {createHtml} from "../../../utils/common-utils";
import PushOnlineArticle from "../../articles/actions/PushOnlineArticle";

type Props = {
    page: 'publication' | 'bundle'
    infos: ArticleSearchResult | AdSearchResult,
    isAdMode: boolean,
    isLinked: boolean,
    unlink: any,
    handleLink?: (elemId: string, name: string, id: string, isBundle?: boolean) => void,
    linkable?: boolean,
    publication?: Publication,
    queryId?: string,
    attachedOnly?: boolean,
    bundle?: BundleResponse
}

const styles = makeStyles(_ => ({
    root: {
        padding: "10px 5px"
    },
    disableText: {
        color: '#bfbdbd'
    }
}));

function ArticleTitleAuthorsPart({article}: { article: ArticleSearchResult }) {
    const {t} = useTranslation()

    const authors = article.authors?.slice(0, 3)
        ?.map((author, index) => <>
                <> — </>
                <Link
                    to={'/' + t('routes.author.root') + '/' + author.id}
                    component={RouterLink}
                >{author.last_name} {author.first_name}
                </Link>
            </>
        )

    return <>
        {authors ? <> {authors}</> : ""}
        {((article.authors?.length ?? 0) > 3 ? <> — …</> : <></>)}
    </>
}

export const OrganizeRowTable = ({
                                     page,
                                     infos,
                                     isAdMode,
                                     isLinked,
                                     unlink,
                                     handleLink,
                                     linkable,
                                     publication,
                                     queryId,
                                     attachedOnly,
                                     bundle
                                 }: Props) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const classes = styles();

    const ad = isAdMode ? infos as AdSearchResult : undefined
    const article = isAdMode ? undefined : infos as ArticleSearchResult

    const handleUnlink = () => unlink()

    const handleAddArticleInBundle = (article: ArticleSearchResult) => {
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CREATE,
            default: {
                bundleId: article.id,
                periodicalId: article.periodicalId,
                publicationId: article.publicationId,
                planNodeId: article.planNodeId,
                type: undefined,
                isBundle: false
            }
        }))
    }

    const handleToggleArticleCover = (article: ArticleSearchResult, flag: boolean) => dispatch(toggleArticleCover({
        article,
        flag
    }))

    const context = () => {
        if (page === 'publication') {
            return !isAdMode ? SearchContext.PUBLICATION_ARTICLE : SearchContext.PUBLICATION_AD
        } else {
            return !isAdMode ? SearchContext.BUNDLE_ARTICLE : SearchContext.BUNDLE_AD
        }
    }

    const forceOptions = () => {
        if (page === 'publication')
            return forcePublicationOptions(isAdMode, publication, queryId)
        else
            return forceBundleOptions(isAdMode, bundle, attachedOnly, publication)
    }

    const getNodesForInfo = (infos) => {
        let plan = publication?.plan;
        if (!plan || null === infos.planNodeId) {
            return null;
        }

        for (const planNode of plan) {
            if (planNode.id === infos.planNodeId) {
                return planNode.name;
            }

            for (const subPlanNode of planNode.sub) {
                if (subPlanNode.id === infos.planNodeId) {
                    return `${planNode.name} > ${subPlanNode.name}`;
                }
            }
        }

        return "";
    }

    return <>
        <TableRow
            draggable={(page !== "publication" || ad !== undefined)}
            onDragStart={e => {
                if (page === "publication" && article) return;
                e.stopPropagation()

                setGlobalTreeDropData({
                    id: infos.id,
                    name: (ad ? ad.name : article!!.title),
                    sub: [],
                    type: ad ? 'ADVERTISEMENT' : 'ARTICLE',
                    planNodeId: article?.planNodeId
                })
            }}>

            <TableCell padding={"none"} align={"center"} size="small">
                <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item>
                        {ad && <ImageIcon/>}
                        {article && !article.isBundle &&
                        <DescriptionIcon className={isLinked ? classes.disableText : ''}/>}
                        {article && article.isBundle &&
                        <BundleIcon className={isLinked ? classes.disableText : ''}/>}
                    </Grid>

                    <Grid item>
                        {article && <Link color="inherit"
                                          to={`/${article.isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${article.id}`}
                                          component={RouterLink}
                                          className={isLinked ? classes.disableText : ''}>
                            {article.id}
                        </Link>}

                        {ad && <Link color="inherit"
                                     to={`/${t('routes.ad.root')}/${ad.id}`}
                                     component={RouterLink}
                                     className={isLinked ? classes.disableText : ''}>
                            {ad.id}
                        </Link>}
                    </Grid>
                </Grid>


            </TableCell>

            <TableCell className={"max-2-lines-text"}>
                <Grid item xs={12}>
                    <span style={{fontWeight: 'bold'}}
                          className={isLinked ? classes.disableText : ''}>
                        {article ? <div dangerouslySetInnerHTML={createHtml(
                            article.title || (
                                article.type == ArticleType.BRIEF ? "(Brève)" : ""
                            ))}
                                        style={{display: 'inline'}}/> : ad!!.name}
                    </span>
                    <span>
                        {article && <ArticleTitleAuthorsPart article={article}/>}
                    </span>
                </Grid>
                <Grid item xs={12} className={isLinked ? classes.disableText : ''}>
                    {getNodesForInfo(infos)}
                </Grid>
            </TableCell>

            <TableCell className={"max-2-lines-text"}>
                {article && article.modificationDate && <DateTime date={article.modificationDate}/>}
                {article && !article.modificationDate && <span>{t('misc.not_provided')}</span>}
                {!article && ad!.modificationDate && <DateTime date={ad!.modificationDate}/>}
                {!article && !ad!.modificationDate && <span>{t('misc.not_provided')}</span>}
            </TableCell>

            {article && <TableCell style={{minWidth: 110}}>
                <ArticleStatusList article={article}/>
            </TableCell>}

            {page === "publication" && article && <TableCell>
                {article.charVolume}
            </TableCell>}

            <TableCell>
                <Grid container direction="row" alignContent="center">
                    <Grid container item direction="row" alignContent="center" justify="flex-end" xs={11}>
                        {isAdMode && isLinked && <Grid item>
                            <IconButton className={classes.root}
                                        onClick={handleUnlink} size="small">
                                <Tooltip title={t('ads.unlink') ?? ''}>
                                    <span><DetachedPlanIcon/></span>
                                </Tooltip>
                            </IconButton>
                        </Grid>}

                        {page === 'bundle' && <>
                            {article && <Grid item>
                                <EditArticle article={article} className={classes.root} size="small"/>
                            </Grid>}

                            {article && article.isBundle && <Grid item>
                                <OrganizeBundle className={classes.root} document={article} size="small"/>
                            </Grid>}
                        </>}

                        {/*todo: réduire la taille de la condition pour qu'on puisse la comprendre*/}
                        {(
                            (page === 'bundle' && article) &&
                            ((article &&
                                (article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY) &&
                                article.lastRequestPlacardProcessStatus === null)
                                && article.isValid)) &&
                        <Grid item>
                            <PdfArticle article={article}
                                        buttonClassName={classes.root}
                                        context={context()}
                                        forceOptions={forceOptions()}
                                        size="small"
                            />
                        </Grid>}

                        {isAdMode && <Auth requiredRoles={[ADMIN, ADMIN_ADS]}>
                            <Grid item>
                                <Link to={`/${t('routes.ad.root')}/${infos.id}/${t('routes.ad.edit')}`}
                                      component={RouterLink}
                                      color={"secondary"}>
                                    <Tooltip title={t('ads.edit') ?? ''}>
                                        <IconButton className={classes.root} size="small">
                                            <EditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            </Grid>
                        </Auth>}

                        <Auth requiredRoles={[ADMIN, ADMIN_ARTICLES, PRODUCER, MANAGER, EDITOR]}
                              periodicalRestriction={article ? article!!.periodicalId : undefined}>
                            <>
                                {(article && !article.isFrontCover) &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.set_front_cover') ?? ''}>
                                        <IconButton className={classes.root} size="small"
                                                    onClick={() => handleToggleArticleCover(article, true)}>
                                            <SetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}

                                {(article && article.isFrontCover) &&
                                <Grid item>
                                    <Tooltip
                                        title={t('misc.unset_front_cover') ?? ''}>
                                        <IconButton className={classes.root} size="small"
                                                    onClick={() => handleToggleArticleCover(article, false)}>
                                            <UnsetFrontCoverIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}
                            </>
                        </Auth>

                        {article && <>
                            <BapArticle
                                buttonClassName={classes.root} articleId={article.id}
                                periodicalId={article.periodicalId} articleStatus={article.status}
                                isValid={article.isValid}
                                context={context()}
                                forceOptions={forceOptions()} size="small"/>

                            {(article && article.status === ArticleStatus.READY) &&
                            <Grid item>
                                <RevertBapArticle buttonClassName={classes.root} article={article} context={context()}
                                                  forceOptions={forceOptions()} size="small"/>
                            </Grid>}

                            {article.lastRequestPlacardProcessStatus !== null &&
                            <Grid item>
                                <UnlockPdfArticle buttonClassName={classes.root} documentId={article.id}
                                                  context={context()}
                                                  forceOptions={forceOptions()} size="small"/>
                            </Grid>
                            }

                            {!isLinked && linkable && handleLink && <Grid item>
                                <IconButton className={classes.root} onClick={() => handleLink(
                                    infos.id,
                                    article.title ?? "",
                                    article.planNodeId,
                                    article.isBundle
                                )} size="small">
                                    <Tooltip title={<>{t(article.isBundle ? 'bundles.link' : 'articles.link')}</>}>
                                        <span><AddToPlanIcon/></span>
                                    </Tooltip>
                                </IconButton>
                            </Grid>}

                            {isLinked && article.isBundle && <Grid item>
                                <IconButton className={classes.root}
                                            onClick={handleUnlink} size="small">
                                    <Tooltip title={<>{t('bundles.unlink')}</>}>
                                        <span><DetachedPlanIcon/></span>
                                    </Tooltip>
                                </IconButton>
                            </Grid>}

                            {isLinked && !article.isBundle && <Grid item>
                                <IconButton className={classes.root}
                                            onClick={handleUnlink} size="small">
                                    <Tooltip title={<>{t('articles.unlink')}</>}>
                                        <span><DetachedPlanIcon/></span>
                                    </Tooltip>
                                </IconButton>
                            </Grid>}


                            {(page === 'bundle' && article.isBundle && article.lastRequestPlacardProcessStatus === null && (article.status === ArticleStatus.IN_PROGRESS
                                || article.status === ArticleStatus.READY)) &&
                            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                                <Grid item>
                                    <Tooltip
                                        title={t('bundles.add_article') ?? ''}>
                                        <IconButton
                                            onClick={() => handleAddArticleInBundle(article)} size="small">
                                            <PostAddIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Auth>}


                            <PushOnlineArticle article={article}
                                               context={SearchContext.ARTICLE} forceOptions={forceOptions()}/>
                        </>}
                    </Grid>
                    {article && <Grid container item alignContent="center" xs={1}>
                        {page === 'publication' ?
                            <ArticlesMoreActionsButtonOnOrganizeCDF
                                article={article}
                                forceOptions={forceOptions()}
                                context={context()} size="small"/>
                            : infos.hasOwnProperty('isBundle') &&
                            <ArticlesMoreActionsButton
                                page="organize-bundle"
                                article={article} size="small"/>
                        }
                    </Grid>}
                </Grid>
            </TableCell>
        </TableRow>
    </>
}

export default OrganizeRowTable

