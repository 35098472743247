import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useTheme
} from "@material-ui/core";
import React, {useContext, useMemo} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {State} from "../../../redux/reducers";
import {useTranslation} from "react-i18next";
import {Link as RouterLink, useLocation} from "react-router-dom";
import ConfirmationDialog from "../../commons/ConfirmationDialog";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {selectAllPage, selectOneElement, updateSearchQueryParam} from "../../../redux/actions/common-search.actions";
import ArticlesListActions from "./ArticlesListActions";
import {makeSortHandler, sortDirection} from "../../../utils/table-utils";
import {tableStyle} from "../../../styles/table.style";
import DateTime from "../../commons/DateTime";
import {readArticleQueryParams} from "../../../utils/article-utils";
import MemoCheckbox from "../../commons/MemoCheckbox";
import {ArticleSearchResult, SearchContext} from "../../../types/common-search";
import {ArticleStatus} from "../../../types/article";
import {fetchPublicationsOfPeriodical} from "../../../redux/actions/publication.actions";
import PostAddIcon from '@material-ui/icons/PostAdd';
import Tooltip from "@material-ui/core/Tooltip";
import {deleteArticle} from "../../../redux/actions/article/article.actions";
import {BundleIcon, ComplexeIcon} from "../../commons/icon/icons";
import {setArticleDialogMode} from "../../../redux/actions/article/article-dialog.actions";
import {ArticleDialogMode} from "../../../redux/reducers/article/article-dialog.reducer";
import DeleteArticleDialogProvider, {DeleteArticleDialogContext} from "../../commons/context/DeleteArticleDialogContext";
import ArticlesMoreActionsButton from "../../commons/button/ArticlesMoreActionsButton";
import Link from "@material-ui/core/Link";
import BapArticle from "../actions/BapArticle";
import PdfArticle from "../actions/PdfArticle";
import RevertBapArticle from "../actions/RevertBapArticle";
import ArticleStatusList from "./ArticleStatusList";
import EditArticle from "../actions/EditArticle";
import OrganizeBundle from "../actions/OrganizeBundle";
import AddInCover from "../actions/AddInCover";
import PushOnlineArticle from "../actions/PushOnlineArticle";
import UnlockPdfArticle from "../actions/UnlockPdfArticle";
import Auth from "../../Auth";
import {ADMIN, EDITOR, MANAGER, ONLINE, PRODUCER} from "../../../utils/roles";
import AuthorsInTable from "./AuthorsInTable";
import {setDirty} from "../../../redux/actions/admin/bundle/admin-custom-bundle.actions";

const mapper = {
    UPDATE: {default: "DESC", DESC: "ASC", ASC: ""},
    ID: {default: "ASC", DESC: "", ASC: "DESC"},
    TITLE: {default: "ASC", DESC: "", ASC: "DESC",}
}

function ArticlesTableComponent() {
    const theme = useTheme()
    const classes = tableStyle(theme)
    const {t} = useTranslation()
    const dispatch = useDispatch()

    const {
        confirmationDialogOpen, openConfirmationDialog,
        articleBeingDeleted
    } = useContext(DeleteArticleDialogContext)

    const searchLocation = useLocation().search;
    const filters = useMemo(() => readArticleQueryParams(searchLocation), [searchLocation]);

    const articles = useSelector((state: State) => {
            return state.search.common[SearchContext.ARTICLE].searchResults as ArticleSearchResult[];
        }, shallowEqual
    );

    const [selectedIds, allPageSelected, loaded]: [string[], boolean, boolean] = useSelector((state: State) => {
        const search = state.search.common[SearchContext.ARTICLE];
        return [
            search.selectedIds,
            search.allPageSelected,
            search.status === 'LOADED'
        ]
    }, shallowEqual);

    const handleAddArticleInBundle = (bundle: ArticleSearchResult) => {
        dispatch(fetchPublicationsOfPeriodical(bundle.periodicalId))
        dispatch(setArticleDialogMode({
            mode: ArticleDialogMode.CREATE,
            default: {
                bundleId: bundle.id,
                periodicalId: bundle.periodicalId,
                publicationId: bundle.publicationId,
                planNodeId: bundle.planNodeId,
                type: undefined,
                isBundle: false
            }
        }))
    }

    const handleArticleDeletion = () => {
        new Promise<void>((resolve) => {
            dispatch(deleteArticle(articleBeingDeleted));
            resolve();
        }).then(() => openConfirmationDialog(false))
    }


    const sortHandler = useMemo(() => makeSortHandler(SearchContext.ARTICLE, mapper, filters, dispatch, updateSearchQueryParam), [searchLocation])

    const handleSelectAll = (e: any, check: boolean) => dispatch(selectAllPage({
        ctx: SearchContext.ARTICLE,
        allPages: check
    }))

    const handleSelectOne = (articleId: string) => () => dispatch(selectOneElement({
        ctx: SearchContext.ARTICLE,
        id: articleId
    }))

    const createHtml = (title: string) => {
        return {__html: title};
    }

    return (<>
            <ConfirmationDialog
                title={t(articleBeingDeleted.isBundle ? 'bundles.delete_confirmation' : 'articles.delete_confirmation')}
                description={''}
                actions={[
                    {label: t('dialogs.cancel'), color: 'default', action: () => openConfirmationDialog(false)},
                    {label: t('dialogs.confirm'), action: handleArticleDeletion, variant: 'contained'}
                ]}
                isOpened={confirmationDialogOpen}
                onClose={() => openConfirmationDialog(false)}
            />

            <TableContainer className={classes.root}>
                <Table aria-label={t('articles.list')}>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" className={classes.cell}>
                                <MemoCheckbox onChange={handleSelectAll} checked={allPageSelected}/>
                            </TableCell>

                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "ID") !== undefined}
                                    direction={sortDirection(filters, "ID")}
                                    onClick={sortHandler("ID")}>
                                    {t('misc.id')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "TITLE") !== undefined}
                                    direction={sortDirection(filters, "TITLE")}
                                    onClick={sortHandler("TITLE")}>
                                    {t('articles.title')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {t('articles.type')}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                {t('articles.publication')}
                            </TableCell>
                            <TableCell className={classes.cell}>
                                <TableSortLabel
                                    active={sortDirection(filters, "UPDATE") !== undefined}
                                    direction={sortDirection(filters, "UPDATE")}
                                    onClick={sortHandler("UPDATE")}>
                                    {t('articles.modified')}
                                </TableSortLabel>
                            </TableCell>
                            <TableCell className={classes.cell} align={"center"}>
                                {t('articles.status.name')}
                            </TableCell>
                            <TableCell align={"center"} className={classes.actionCell}>
                                {t('misc.actions')}
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {(selectedIds && selectedIds.length > 0) &&
                        <TableRow key={"selectedRow"} className={classes.actionGroup}>
                            <TableCell colSpan={9} className={classes.cell}>
                                <ArticlesListActions/>
                            </TableCell>
                        </TableRow>}

                        {articles.length > 0 && articles.map(article =>
                            <TableRow key={article.id} className={`${classes.row}`}
                                      hover={true}>
                                <TableCell padding="checkbox" className={classes.cell} width={'5%'}>
                                    <MemoCheckbox onChange={handleSelectOne(article.id)}
                                                  checked={selectedIds.indexOf(article.id) !== -1}/>
                                </TableCell>

                                <TableCell className={classes.cell} width={'10%'}>
                                    <Link color="inherit"
                                          to={`${article.isBundle ? t('routes.bundle.root') : t('routes.article.root')}/${article.id}`}
                                          component={RouterLink}>
                                        {article.id}
                                    </Link>
                                </TableCell>

                                <TableCell className={classes.cell} width={'35%'}>
                                    <div dangerouslySetInnerHTML={createHtml(article.title ?? '')}/>
                                    {article.authors && <AuthorsInTable authors={article.authors}/>}
                                </TableCell>

                                <TableCell className={classes.cell} width={'10%'}>
                                    <Grid container spacing={1} direction={"row"}>
                                        {article.isBundle && <Grid item>
                                            <span><BundleIcon/></span>
                                        </Grid>}

                                        {(article.nature && article.nature === 'COMPLEXE') && <Grid item>
                                            <span><ComplexeIcon/></span>
                                        </Grid>}


                                        <Grid item>
                                            {t(`enums.articles.type.${article.type}.main`)}
                                        </Grid>
                                    </Grid>
                                </TableCell>

                                <TableCell className={classes.cell} width={'10%'}>
                                    {article.publicationId &&
                                    <Link
                                        to={t('routes.publication.root') + '/' + article.publicationId + '/' + t('routes.publication.organize')}
                                        component={RouterLink}
                                        style={{color: "black"}}
                                    >
                                        {article.publication}
                                    </Link>}
                                </TableCell>

                                <TableCell className={classes.cell} width={'10%'}>
                                    {article.modificationDate && <DateTime date={article.modificationDate}/>}
                                    {!article.modificationDate && <span>{t('misc.not_provided')}</span>}
                                </TableCell>

                                <TableCell className={classes.cell} width={'10%'}>
                                    <ArticleStatusList article={article}/>
                                </TableCell>

                                <TableCell className={classes.cell} width={'22%'}>
                                    <Grid container justify={"flex-end"} alignItems="center">
                                        <Auth requiredRoles={[ADMIN, MANAGER, EDITOR, PRODUCER, ONLINE]}
                                              periodicalRestriction={article.periodicalId}>
                                            {/* edit article */}
                                            <EditArticle size="small" article={article}/>

                                            {/* organize bundle */}
                                            {article.isBundle && <OrganizeBundle size="small" document={article}/>}

                                            {(!article || (article && (article.status === ArticleStatus.IN_PROGRESS || article.status === ArticleStatus.READY)
                                                && article.lastRequestPlacardProcessStatus === null) && article.isValid) &&
                                            <PdfArticle size="small" article={article}
                                                        context={SearchContext.ARTICLE}/>}

                                            <PushOnlineArticle article={article}
                                                               context={SearchContext.ARTICLE}/>

                                            {/* mettre en couv */}
                                            <AddInCover size="small" article={article}/>

                                            {!article.nextPublicationOnlineDate && <>
                                                <BapArticle size="small"
                                                            articleId={article.id}
                                                            periodicalId={article.periodicalId}
                                                            articleStatus={article.status}
                                                            context={SearchContext.ARTICLE}
                                                            isValid={article.isValid}/>

                                                {article && article.status === ArticleStatus.READY &&
                                                <RevertBapArticle size="small" article={article}
                                                                  context={SearchContext.ARTICLE}/>}
                                            </>
                                            }
                                            {article.lastRequestPlacardProcessStatus !== null &&
                                            <UnlockPdfArticle size="small" documentId={article.id}
                                                              context={SearchContext.ARTICLE}/>}


                                            {(article.isBundle && article.lastRequestPlacardProcessStatus === null && (article.status === ArticleStatus.IN_PROGRESS
                                                || article.status === ArticleStatus.READY)) &&
                                            <Auth requiredRoles={[ADMIN, MANAGER, EDITOR]}>
                                                <Grid item>
                                                    <Tooltip
                                                        title={t('bundles.add_article') ?? ''}>
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleAddArticleInBundle(article)}>
                                                            <PostAddIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </Auth>}

                                            <Grid item>
                                                <ArticlesMoreActionsButton size="small" article={article}/>
                                            </Grid>
                                        </Auth>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        )}

                        {(articles.length === 0 && loaded) && (
                            <TableRow>
                                <TableCell colSpan={9} align={"center"}>
                                    {t('table.no_result')}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

const ArticlesTable = () => <DeleteArticleDialogProvider><ArticlesTableComponent/></DeleteArticleDialogProvider>
export default ArticlesTable

